<template>
    <span class="d-none">ProjectData</span>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ProjectData',
    data() {
        return {
            projectOptions: [],
            projectFilterOptions: [],
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.projectDataList();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.projectDataList();
            }
            return newValue
        },
    },
    methods: {
        async projectDataList() {
            if (this.$store.state.isActiveToken == '1' && this.$store.state.project.loadProjectData === 1) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                await axios.get(process.env.VUE_APP_API_URL + 'project/active')
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            const projectOpts = resp.data.data.records
                            this.projectOptions = [];
                            this.projectFilterOptions = [];
                            var len = projectOpts.length;
                            for (var i = 0; i < len; i++) {
                                this.projectOptions.push({
                                    id: projectOpts[i]._id,
                                    text: projectOpts[i].title
                                });
                                this.projectFilterOptions.push({
                                    value: projectOpts[i]._id,
                                    label: projectOpts[i].title
                                });
                            }
                            this.$store.state.project.loadProjectData = 2
                            this.$store.state.project.ProjectList = this.projectOptions
                            this.$store.state.project.ProjectFilterList = this.projectFilterOptions
                        }
                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                    })
            }
        },
    },
}
</script>