<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8V14M12 14L14 11.4286M12 14L10 11.4286" stroke="black" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        <path d="M15 16H12H9" stroke="black" stroke-width="1.5" stroke-linecap="round" />
        <path
            d="M20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34314 20 5.17158 18.8284C4 17.6569 4 15.7712 4 12C4 8.22876 4 6.34314 5.17158 5.17158C6.34314 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17158C19.6074 5.95057 19.8685 7.04524 19.9559 8.8"
            stroke="black" stroke-width="1.5" stroke-linecap="round" />
    </svg>
</template>
<script>
    export default {
        name: 'DownloadSvgIconPage',
    }
</script>

