<template>
    <div class="card border-0">
        <div class="sectionclsbodymain tsk_body_right_back">
            <div class="serach_controls_main tsk_controls_main_cls">
                <div class="serach_controls_main_row1">
                    <div class="tsk_top_filter_left">
                        <div class="serach_controls me-2">
                            <SearchSvgIcon />
                            <input type="search" class="formmginput-control tsk_search" id="searchTxtId" placeholder="Search..." v-on:keyup="reloadSearch()">
                        </div>
                        <div class="serach_controlsx w-200 me-2">                    
                            <Select2 v-model="myValue" 
                            v-bind:id="'teamStatusFilterId'"
                            :select=2 
                            :options="$store.state.stausOptions" 
                            :placeholder="'Select Status'"
                            :settings="{ minimumResultsForSearch: -1 }"
                            @change="myChangeEvent($event)" 
                            @select="mySelectEvent($event)"
                            />
                        </div>
                        <div class="serach_controlsx w-200">                    
                            <Multiselect v-model="myValueRole" mode="multiple" :hide-selected="false" :caret="true" :searchable="true" :create-option="true" :options="$store.state.role.RoleFilterList" @change="myChangeEvent($event)" placeholder="Select Roles" v-bind:name="'companyId[]'" v-bind:id="'companyFilterId'" />    
                        </div>
                    </div>                   
                    <div class="tsk_top_filter_right">   
                        <AddEditTeam @reload-table-data="reloadSearch" ref="childComponentTeamRef"></AddEditTeam>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive pageTblHeightCls">
                <table class="table table-bordered team_member_list_tsk" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody class="tbodyCls shimmer-wrapper" v-if="tableLoader === 1">
                        <tr class="text-center " v-for="index in 15" :key="index">
                            <td v-for="index1 in 5" :key="index1">
                                <span class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="tbodyCls" v-else-if="tableLoader === 2">
                        <tr class="text-center">
                            <td colspan="7">
                                <ComponentNoData />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(item) in teamList" :key="item._id">
                            <td>
                                <div class="d-flex align-content-center sldteam-membert" v-if="item.initials == null">
                                    <div class="mg-thumbnail">
                                        <img v-bind:src="img_url + '' + item.user_image" v-bind:alt="item.user_name" />
                                    </div>
                                    <span class="">{{ item.name }}</span>
                                </div>
                                <div class="d-flex align-content-center sldteam-membert" v-else>
                                    <div class="mg-thumbnail tsk_name">
                                        <span v-bind:title="item.name">{{item.initials}}</span>
                                    </div>
                                    <span class="">{{ item.name }}</span>
                                </div>
                            </td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.role }}</td>
                            <td v-if="item.status === true">
                                <div class="form-check form-switch mr-1">
                                    <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" checked="" v-on:click="teamStatusModal(item._id, item.status)">
                                    <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                </div>
                            </td>
                            <td v-else>
                                <div class="form-check form-switch mr-1">
                                    <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" v-on:click="teamStatusModal(item._id, item.status)">
                                    <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                </div>
                            </td>
                            <td>
                                <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="modalEditOpen(item._id)" title="Edit">
                                    <EditSvgIcon />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <input type="hidden" class="teamValId" id="teamValId"/>
            <input type="hidden" class="teamStatusId" id="teamStatusId"/>
            <div class="d-flex px-3 databottom justify-content-between align-content-center" v-if="tableLoader == 3">
                <p v-if="page * pageLength > totalRecord">Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{
                    formatMoney(totalRecord) }} of {{ formatMoney(totalRecord) }} entries</p>
                <p v-else>Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{ formatMoney(page * pageLength) }} of {{
                    formatMoney(totalRecord) }} entries</p>
                <vs-pagination :total="totalLenght" :max="8" v-model="page" @click="reloadPagination(page)"></vs-pagination>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from 'axios'
import AddEditTeam from './AddEditTeam.vue'
import Select2 from 'vue3-select2-component';
import $ from "jquery";
import Multiselect from '@vueform/multiselect'
import EditSvgIcon from '../Widgets/Icon/EditSvgIcon.vue'
// import ComponentLoader from '../Widgets/Icon/ComponentLoader.vue'
import ComponentNoData from '../Widgets/Icon/ComponentNoData.vue'
import SearchSvgIcon from '../Widgets/Icon/SearchSvgIcon.vue'
import {
    formatMoney
} from '../../helper'
import {
    ref
} from 'vue';
export default {
    name: 'TeamMemberListingPage',
    components: {
        Select2,
        AddEditTeam,
        Multiselect,
        EditSvgIcon,
        // ComponentLoader,
        ComponentNoData,
        SearchSvgIcon
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            currentx: 5,
            tableLoader: 1,
            page: 1,
            pageLength: this.$store.state.showEntries,
            searchKey: '',
            totalRecord: 0,
            totalLenght: 0,
            teamList: [],
            myValueRole: [],
            myValue: 'true',
            taskListHeight: 0,
            taskMainListHeight: 0,
        }
    },
    async mounted() {
        this.$store.state.pageTitle = 'Team Member'
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.loadTeamData();
        }
        let temps = this
        $("body").on("click", '#searchTxtId', function () {
            if ($('#searchTxtId').val() != '') {
                $('#searchTxtId').val('');
                this.searchKey = '';
                this.page = 1;
                temps.reloadSearch()
            }
        });
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.loadTeamData();
            }
            return newValue
        },
    },
    methods: {
        formatMoney,
        checkHeightScroll() {
            $('.table').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.table').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom
            var tabHei = headNavCls + tsk_tabs + tsk_controls_main_cls + databottom
            cntSubvar = this.windowHeight - cntSubvar - 30
            tabHei = this.windowHeight - tabHei - 30
            this.taskMainListHeight = tabHei;
            $('tbody').height(cntSubvar);
            $('.pageTblHeightCls').height(tabHei);
            this.checkHeightScroll();

        },
        async reloadPagination(itemObjKey) {
            if (itemObjKey > 0 && itemObjKey !== -1 && itemObjKey <= this.totalLenght) {
                this.page = itemObjKey;
                this.loadTeamData();
            }
        },
        buttonId(id) {
            return "team" + id;
        },
        async reloadSearch() {
            const searchCls = document.getElementById('searchTxtId').value;
            this.searchKey = searchCls.trim();
            this.page = 1;
            this.loadTeamData();
        },
        myChangeEvent(val) {
            this.myValueRole = val
            this.loadTeamData();
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text })
            this.page = 1;
            this.loadTeamData();
        },
        async teamStatusModal(_id, status) {
            document.getElementById('teamStatusId').value = status
            document.getElementById('teamValId').value = _id
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Confirm`,
                text: 'Are you sure you want to update status?',
                accept: this.teamStatusChange,
                cancel: this.teamStatusCloseChange
            })
        },
        async teamStatusCloseChange() {
            // const teamStatusId = document.getElementById('teamStatusId').value
            let teamValId = document.getElementById('teamValId').value
            let teamValIdsds = true;
            if (teamValId === 'false') {
                teamValIdsds = false;
            }
            document.getElementById("team" + teamValId).checked = teamValIdsds;
            // this.loadTeamData();
        },
        async teamStatusChange() {
            this.formLoader = 2
            const teamStatusId = document.getElementById('teamStatusId').value
            let teamValId = document.getElementById('teamValId').value
            let teamValIdsds = false;
            if (teamStatusId === 'false') {
                teamValIdsds = true;
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'user/status-update', {
                status: teamValIdsds,
                user_id: teamValId,
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        this.loadTeamData();
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.data;
                        this.errorRes = resp.data.message;
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                })
        },
        async loadTeamData() {
            this.tableLoader = 1
            var roleIds = [];
            let token = localStorage.getItem('mgTasktoken');
            if (!token) {
                this.$store.state.user.accessToken = ''
            }
            let teamStatusFilterId = document.getElementById('teamStatusFilterId').value
            let status = teamStatusFilterId;
            roleIds = this.myValueRole;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'user/list', {
                    params: {
                        page: this.page,
                        pageLength: this.pageLength,
                        searchKey: this.searchKey,
                        status: status,
                        role_id: roleIds,
                    }
                })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.teamList = []
                        this.teamList = resp.data.data.records
                        this.totalRecord = resp.data.data.totalRecord
                        this.totalLenght = this.totalRecord / this.pageLength
                        this.totalLenght = Math.ceil(this.totalLenght);
                        if (this.teamList.length > 0) {
                            this.tableLoader = 3
                        } else {
                            this.tableLoader = 2
                        }
                        setTimeout(async () =>
                            this.onResize(),
                        100);
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
        async modalEditOpen(team_id) {
            $('.teamValId').val(team_id);
            this.callChildTeamMethod();
        },
    },
    setup() {
        const childComponentTeamRef = ref(null);
        const callChildTeamMethod = () => {
            if (childComponentTeamRef.value) {
                childComponentTeamRef.value.modalEditOpen();
            }
        };
        return {
            childComponentTeamRef,
            callChildTeamMethod,
        };
    },

}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
