<template>
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="30" height="20" rx="3" stroke="#44546F" stroke-width="2"/>
        <path d="M1 11H31V18C31 20.2091 29.2091 22 27 22H5C2.79086 22 1 20.2091 1 18V11Z" fill="#44546F"/>
        <rect x="4" y="14" width="12" height="4" rx="2" fill="white"/>
        <circle cx="26" cy="16" r="2" fill="white"/>
    </svg>
</template>
<script>
export default {
    name: 'TitleTaskPreSvgIconPage',
}
</script>