<template>
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="2" width="26" height="4" rx="2" fill="#44546F"/>
        <rect x="3" y="9" width="22" height="4" rx="2" fill="#44546F"/>
        <rect x="3" y="16" width="18" height="4" rx="2" fill="#44546F"/>
    </svg>
</template>
<script>
export default {
    name: 'CommentTaskPreSvgIconPage',
}
</script>