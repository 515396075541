<template>
    <!-- <div class="card overflow-hidden"> -->
    <div class="card border-0">
        <!-- 
            {{ taskOptions }}
        {{ $store.state.project.ProjectFilterList }} 
        -->
        <div class="sectionclsbodymain tsk_body_right_back">
            <div class="serach_controls_main tsk_controls_main_cls">
                <div class="serach_controls_main_row1">
                    <div class="tsk_top_filter_left">
                        <div class="serach_controlsx w-200 me-2">                    
                            <Multiselect 
                                v-model="filterProjectIds" 
                                mode="multiple" 
                                :hide-selected="false" 
                                :caret="true" 
                                :searchable="true" 
                                :create-option="true" 
                                :options="$store.state.project.ProjectFilterList" 
                                placeholder="Select Projects" 
                                v-bind:name="'filterProjectIds[]'" 
                                v-bind:id="'filterProjectIds'"
                                @change="myChangeProjectEvent($event)"  />                                    
                        </div>
                        <div class="serach_controlsx w-200 me-2">                    
                                <Multiselect 
                                    v-model="filterTaskIds" 
                                    mode="multiple" 
                                    :hide-selected="false" 
                                    :caret="true" 
                                    :searchable="true" 
                                    :create-option="true" 
                                    :options="taskOptions" 
                                    placeholder="Select Task" 
                                    v-bind:name="'filterTaskIds[]'" 
                                    v-bind:id="'filterTaskIds'"
                                    @change="myChangeTaskEvent($event)"  />                                    
                            </div>
                        <div class="serach_controlsx w-200">   
                            <Multiselect
                                v-model="filterUsersIds"
                                :caret="true" 
                                mode="multiple"
                                placeholder="Select Teams"
                                :searchable="true"
                                v-bind:name="'filterUsersIds[]'"
                                v-bind:id="'filterUsersIds'" 
                                track-by="label"
                                label='label'
                                :close-on-select="false"
                                @change="myChangeUsersEvent($event)"
                                :hide-selected="false"
                                :options="$store.state.teamMember.TeamMemberImgFilterList"
                            >
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div
                                    class="multiselect-tag is-user"
                                    :class="{
                                        'is-disabled': disabled
                                    }"
                                >
                                    <div v-if="option.initials == null">  
                                        <img :src="img_url + '' + option.user_image">
                                        {{ option.label }}
                                    </div>
                                    <div v-else>  
                                        {{ option.initials }} {{ option.label }}
                                    </div>
                                    <span
                                    v-if="!disabled"
                                    class="multiselect-tag-remove"
                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                    >
                                    <span class="multiselect-tag-remove-icon"></span>
                                    </span>
                                </div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <div v-if="option.initials == null">  
                                        <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                    </div>
                                    <div v-else>
                                        <span class="empIniName">
                                            {{ option.initials }} 
                                        </span>
                                        {{ option.label }}
                                    </div>
                            </template>
                        </Multiselect>                 
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <div class="card-body py-0">
            <ul class="notilinkCls notification_main_cls shimmer-wrapper" id="dropdownMenuForNotification" aria-labelledby="dropdownMenuLinkForNotification" v-if="notiFormLoader === 1">
                <li class="border-success notification_card mb-2 flex-column" v-for="index in 8" :key="index">
                    <div class="notification_card_header w-100">
                        <div class="notification_card_left w-75">
                            <div class="mg-item-noti_thumb">
                                <div class="mg-thumbnail shimmer-line shimmer-line-br shimmer-line-wd shimmer-animate"></div>                              
                            </div>
                            <div class="notiTitle shimmer-line shimmer-line-br shimmer-line-w40 shimmer-animate ml-2"></div>
                        </div>
                        <div class="notification_card_right w-25">
                            <div class="notiDateTime shimmer-line shimmer-line-br shimmer-line-w30 shimmer-animate float-right"></div>
                        </div>  
                    </div>       
                    <div class="notiMsg_body w-100">
                        <div class="notiMsg mb-2 shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
                        <div class="notiTaskTitle mb-2 shimmer-line shimmer-line-br shimmer-line-w40 shimmer-animate"></div>
                        <div class="notiTaskTitle mb-2 shimmer-line shimmer-line-br shimmer-line-w60 shimmer-animate"> </div>
                        <div class="notiProTitle shimmer-line shimmer-line-br shimmer-line-w80 shimmer-animate"></div>
                    </div>                
                </li>
            </ul>
            <ul class="notilinkCls notification_main_cls" id="dropdownMenuForNotification" aria-labelledby="dropdownMenuLinkForNotification" v-else-if="notiFormLoader === 2">
                <li class="notilinkCls" >
                    <ComponentNoData />
                </li>
            </ul>
            <ul class="notilinkCls notification_main_cls" id="dropdownMenuForNotification" aria-labelledby="dropdownMenuLinkForNotification" v-else>
                <li class="border-success notification_card card mb-2" v-for="(item) in notiData" :key="item._id">
                    <div class="notification_card_header">
                        <div class="notification_card_left">
                            <div class="mg-item-noti_thumb">
                                <div class="mg-thumbnail" v-if="item.initials == null">
                                    <img v-bind:src="img_url + '' + item.user_image" v-bind:alt="item.name" v-bind:title="item.name"/>
                                </div>
                                <div class="mg-thumbnail" v-else>
                                    <span v-bind:title="item.name">{{ item.initials }}</span>
                                </div>
                            </div>
                            <div class="notiTitle">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="notification_card_right">
                            <div class="notiDateTime" v-b-tooltip.hover v-bind:title="dateChangeFormat(item.createdAt)">
                                <timeago :datetime="item.createdAt"/> 
                            </div>
                        </div>  
                    </div>
                    <div class="notiMsg_body">
                        <div class="notiMsg" >
                            {{ item.message }}
                        </div>
                        <div class="notiTaskTitle" v-if="item.task_no != ''">
                            <span>Ticket: </span> #{{ item.task_no }}
                        </div>
                        <div class="notiTaskTitle"  v-if="item.task_title != ''">
                            <!-- <span>Task: </span>{{ item.task_title }} -->
                            <span>Task: </span><router-link :to="`/tasks-kanban?id=${item.task_id}`" class="" exact>
                                    {{ item.task_title }}
                                </router-link>
                        </div>
                        <div class="notiProTitle" >
                            <!-- <span>Project: </span><router-link :to="`/project/${item.project_id}/overview`" class="" exact> -->
                            <span>Project: </span><router-link :to="`/project/${item.project_id}/task-list`" class="" exact>
                                    {{ item.project_title }}
                                </router-link>
                            
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="d-flex px-3 databottom justify-content-between align-content-center" v-if="notiFormLoader === 3">
            <p v-if="page * pageLength > totalRecord">Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{
                formatMoney(totalRecord) }} of {{ formatMoney(totalRecord) }} entries</p>
            <p v-else>Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{ formatMoney(page * pageLength) }} of {{
                formatMoney(totalRecord) }} entries</p>
            <vs-pagination :total="totalLenght" :max="8" v-model="page" @click="reloadPagination(page)"></vs-pagination>
        </div>
    </div>
    <TeamMemberData />
    <ProjectData />
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Multiselect from '@vueform/multiselect'
import ProjectData from '../components/Projects/ProjectData.vue'
import $ from "jquery";
import ComponentNoData from '@/components/Widgets/Icon/ComponentNoData.vue';
import TeamMemberData from '../components/TeamMember/TeamMemberData.vue'
// import ComponentLoader from '@/components/Widgets/Icon/ComponentLoader'
import {
    formatMoney
} from '../helper'

export default {
    name: 'NotificationsPage',
    components: {
        Multiselect,
        ProjectData,
        ComponentNoData,
        TeamMemberData,
        // ComponentLoader,
        
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            notiCntData: 0,
            notiFormLoader: 1,
            notiData: [],
            notiIdData: [],
            filterProjectIds: [],
            filterTaskIds: [],
            filterUsersIds: [],
            taskOptions: [],
            page: 1,
            // pageLength: this.$store.state.showEntries,
            pageLength: 16,
            totalLenght: 0,
            totalRecord: 0,
        }
    },
    mounted() {
        const bHeight = $('body').height();
        $('#content').addClass('firstHeightCls')
        $('.firstHeightCls').height(bHeight-23) 
        this.$store.state.pageTitle = 'Notifications'
        this.getNotifications();
        this.taskDataList();
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        formatMoney,
        checkHeightScroll() {
            $('.table').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.table').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom

            cntSubvar = this.windowHeight - cntSubvar - 30

            // $('.notilinkCls').height(cntSubvar);
             $('.notilinkCls').css('max-height',cntSubvar);
            this.checkHeightScroll();

        },
        myChangeProjectEvent(val) {
            this.filterProjectIds = val
            this.getNotifications();
            this.taskDataList();
        },
        myChangeTaskEvent(val) {
            this.filterTaskIds = val
            this.getNotifications();
        },
        myChangeUsersEvent(val) {
            this.filterUsersIds = val
            this.getNotifications();
        },
        notificationIds(id) {
            return this.notiIdData.push(id);
        },
        dateChangeFormat(dateVal) {
            return moment(dateVal).format('DD-MM-YYYY hh:mm A');
        },
        async reloadPagination(itemObjKey) {
            if (itemObjKey > 0 && itemObjKey !== -1 && itemObjKey <= this.totalLenght) {
                this.page = itemObjKey;
                this.getNotifications();
            }
        },
        async getNotifications() {
            this.notiFormLoader = 1
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'dashboard/get-notifications', {
                params: {
                    page: this.page,
                    pageLength: this.pageLength,
                    project_id: this.filterProjectIds,
                    task_id: this.filterTaskIds,
                    user_id: this.filterUsersIds,
                }
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    
                    this.notiData = resp.data.data.records
                    this.notiCntData = resp.data.data.length
                    this.totalRecord = resp.data.data.totalRecord
                    this.totalLenght = this.totalRecord / this.pageLength
                    this.totalLenght = Math.ceil(this.totalLenght);
                    if (this.notiData.length > 0) {
                        this.notiFormLoader = 3
                    } else {
                        this.notiFormLoader = 2
                    }
                    setTimeout(async () =>
                        this.onResize(),
                    300);
                }
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.notiFormLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        async taskDataList() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'task/active-task-list', {
                params: {
                    project_id: this.filterProjectIds,
                }
            })
            .then((resp) => {
                console.log(resp)
                if (resp.status == 200 && resp.data.status == 'success') {
                    const taskOpts = resp.data.data.records
                    this.taskOptions = [];
                    var len = taskOpts.length;
                    for (var i = 0; i < len; i++) {
                        this.taskOptions.push({
                            value: taskOpts[i]._id,
                            label: taskOpts[i].title+' ( #'+ taskOpts[i].task_no +')'
                        });
                    }
                }
            })
            .catch(error => {
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
            })
        },
    },
}
</script>
<style scoped>
.notilinkCls{
    overflow: auto;
}
</style>