<template>
    <div class="box">
        <div class="boxHeaderCls">
            <h2 class="float-left shimmer-line shimmer-line-w30 shimmer-animate"></h2>
            <h6 class="taskKanbanCls float-right shimmer-line-w20 shimmer-line shimmer-animate"></h6>
        </div>      
        <div class="sdssdsad"> 
            <div class="boxbodyCls list-group w-full">
                <div class="list-group-item tsk_item_card_cls boardClsId" v-for="index in 5" :key="index">
                    <div class="itemMainClsSim taskTitleClsSim">
                        <div class="kanTitleCls">
                            <span class="shimmer-line-w40 shimmer-line shimmer-animate"></span>
                            <b class="shimmer-line-w20 shimmer-line shimmer-animate"></b>
                        </div>
                        <div class="kanPriNameCls">
                            <div class="shimmer-line-w20 shimmer-line shimmer-animate"></div>     
                        </div>
                        <div class="kanProNameCls">
                            <div class="shimmer-line-full shimmer-line shimmer-animate"></div>     
                        </div>
                        <div class="kanPriFooterCls d-flex align-items-center"> 
                            <div class="kanFooterCmt_right w-100">
                                <div class="kanFooterDateCls float-left ms-0 shimmer-line-w10 shimmer-line shimmer-animate">
                                </div>
                                <div class="kanFooterCmtCls float-left  ms-2 shimmer-line-w10 shimmer-line shimmer-animate">
                                </div>
                                <div class="kanFooterAttCls float-left  ms-2 shimmer-line-w10 shimmer-line shimmer-animate">
                                </div>
                            </div>
                            <div class="kanFooterCmt_left">
                                <div class="kanTitleClssdsd d-flex">
                                    <div class="mg-thumbnail shimmer-line shimmer-animate me-1"></div>
                                    <div class="mg-thumbnail shimmer-line shimmer-animate me-0"></div>     
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
export default {
    name: 'shimmerPage'
}
</script>