<template>
    <PriorityData />
    <TeamMemberData />
    <ProjectData />
    <BoardData />
    <LabelData />
    <div class="card overflow-hidden">
        <nav class="tsk_tabs">
            <div class="nav nav-tabs m-0" id="nav-tab" role="tablist">
                <!-- <router-link :to="`/project/${$route.params.projectId}/overview`" class="nav-link" id="nav-list-tab">
                    <span class="tabLinkCls">Overview</span>
                </router-link> -->
                <router-link :to="`/project/${$route.params.projectId}/task-list`" class="nav-link" id="nav-karban-tab">
                    <span class="tabLinkCls">List</span>
                </router-link>
                <button class="nav-link active tabLinkCls">Kanban</button>
                <router-link :to="`/project/${$route.params.projectId}/archive`" class="nav-link" id="nav-archive-tab">
                    <span class="tabLinkCls">Archive</span>
                </router-link>
            </div>
            <div class="mg-right_divselt">
                    <vs-button class="mg-btn-gold me-3" v-on:click="manageLabelsBtn"  color="primary" text-color="whiteprimary"><LabelsSvgIcon />Manage labels</vs-button>
                    <vs-button class="mg-btn-gold"  v-on:click="addTaskBtn" color="primary" text-color="whiteprimary"><AddSvgIcon />Add Task</vs-button>  
                </div>
        </nav>
        <div class="tab-content p-0" id="nav-tabContent">
            <div class="tab-pane fade active show" id="nav-list" role="tabpanel" aria-labelledby="nav-list-tab">
                <div class="card border-0">
                    <div class="sectionclsbodymain tsk_body_right_back">
                        <Karban :projectId="projectId"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import ProjectsKanban from '../components/Projects/IndexKanban.vue'
import PriorityData from '../components/Priority/PriorityData.vue'
import TeamMemberData from '../components/TeamMember/TeamMemberData.vue'

import Karban from '../components/Tasks/karban.vue'
import ProjectData from '../components/Projects/ProjectData.vue'
import BoardData from '../components/Board/BoardData.vue'
import LabelData from '../components/Label/LabelData.vue'
import AddSvgIcon from '../components/Widgets/Icon/AddSvgIcon.vue'
import LabelsSvgIcon from '../components/Widgets/Icon/LabelsSvgIcon.vue'

import axios from 'axios'
import $ from "jquery";
export default {
    name: 'ProjectsKanbanPage',
    components: {
        // ProjectsKanban,
        PriorityData,
        TeamMemberData,

        ProjectData,
        BoardData,
        LabelData,
        Karban,
        AddSvgIcon,
        LabelsSvgIcon,
    },
    data() {
        return {
            projectId: this.$route.params.projectId
        }
    },
    async mounted() {
         $('.projectBethCls').show();
        this.projectId = this.$route.params.projectId
        $('.projectMenuCls').addClass('router-link-active')
        await this.getProjectDetail();
        this.$store.state.projectIdSelected = ''
    },
    beforeRouteLeave(to, from, next) {
        console.log(to);
        console.log(from);
        console.log(next);
        console.log('next');
         $('.projectBethCls').hide();
        $('.projectMenuCls').removeClass('router-link-active')
        next()
    },
    methods: {
        async getProjectDetail() {
            $('.loadimg').show();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'project/get', {
                project_id: this.projectId
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$store.state.pageTitle = resp.data.data.title              
                    $('.loadimg').hide();
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        async addTaskBtn() {
            this.$store.state.projectIdSelected = this.projectId
            document.getElementById('addTaskMainBtn').click();
        },
        async manageLabelsBtn() {
            document.getElementById('addManageLabelsBtn').click();
        }
    },
}
</script>
