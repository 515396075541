<template>
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.1538 2H9.84615C8.82655 2 8 2.89543 8 4C8 5.10457 8.82655 6 9.84615 6H30.1538C31.1734 6 32 5.10457 32 4C32 2.89543 31.1734 2 30.1538 2Z" fill="#44546F"/>
        <path d="M26.1818 9H9.81818C8.81403 9 8 9.89543 8 11C8 12.1046 8.81403 13 9.81818 13H26.1818C27.186 13 28 12.1046 28 11C28 9.89543 27.186 9 26.1818 9Z" fill="#44546F"/>
        <path d="M23.1111 16H9.88889C8.84568 16 8 16.8954 8 18C8 19.1046 8.84568 20 9.88889 20H23.1111C24.1543 20 25 19.1046 25 18C25 16.8954 24.1543 16 23.1111 16Z" fill="#44546F"/>
        <circle cx="2" cy="4" r="2" fill="#44546F"/>
        <circle cx="2" cy="11" r="2" fill="#44546F"/>
        <circle cx="2" cy="18" r="2" fill="#44546F"/>
    </svg>
</template>
<script>
export default {
    name: 'ActivityTaskPreSvgIconPage',
}
</script>