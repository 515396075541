import { createStore } from "vuex";
import {UserModule} from './module/UserModule'
import {RoleModule} from './module/RoleModule'
import {PriorityModule} from './module/PriorityModule'
import {TeamMemberModule} from './module/TeamMemberModule'
import {BoardModule} from './module/BoardModule'
import {ProjectModule} from './module/ProjectModule'
import {LabelModule} from './module/LabelModule'

export default createStore({
    state: {
        pageTitle: 'Dashboard',
        isRefreshToken: 1,
        NotifiCheckVal: 0,
        isLogin: 1,
        showEntries: 15,
        attemptLogin: 1,
        stausOptions: [{ id: 2, text: 'All' }, { id: true, text: 'Active' }, { id: false, text: 'Inactive' }],
        projectIdSelected: '',
    },
    modules: {
        user: UserModule,
        role: RoleModule,
        priority: PriorityModule,
        teamMember: TeamMemberModule,
        board: BoardModule,
        project: ProjectModule,
        label: LabelModule,
    }
})