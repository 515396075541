<template>
    <span class="d-none">PriorityData</span>
</template>
<script>
import axios from 'axios'
export default {
    name: 'PriorityData',
    data() {
        return {
            priorityOptions: [],
            priorityFilterOptions: [],
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.priorityDataList();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.priorityDataList();
            }
            return newValue
        },
    },
    methods: {
        async priorityDataList() {
            if (this.$store.state.isActiveToken == '1' && this.$store.state.priority.loadPriorityData === 1) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                await axios.get(process.env.VUE_APP_API_URL + 'priority/active')
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            const priorityOpts = resp.data.data.records
                            this.priorityOptions = [];
                            this.priorityFilterOptions = [];
                            var len = priorityOpts.length;
                            for (var i = 0; i < len; i++) {
                                this.priorityOptions.push({
                                    id: priorityOpts[i]._id,
                                    text: priorityOpts[i].priority
                                });
                                this.priorityFilterOptions.push({
                                    value: priorityOpts[i]._id,
                                    label: priorityOpts[i].priority
                                });
                            }
                            this.$store.state.priority.loadPriorityData = 2
                            this.$store.state.priority.PriorityList = this.priorityOptions
                            this.$store.state.priority.PriorityFilterList = this.priorityFilterOptions
                        }
                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                    })
            }
        },
    },

}

</script>