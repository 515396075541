<template>
    <vs-button class="mg-btn-gold" color="primary" text-color="whiteprimary"  v-on:click="modalOpen">Add Priority</vs-button>    
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#priorityModals" id="addPriorityBtn"> </button>             
    <div class="modal fade right" id="priorityModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="priorityEditId" id="priorityEditId">
                    <h5 class="modal-title">{{ modalType }} Priority</h5> 
                    <button type="button" class="btn-close" id="closePriorityPop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="priorityFrm" id="priorityFrm">
                    <div class="modal-body"> 
                        <span v-if="errorMessage != ''">
                            <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                        </span>
                        <div class="formmginput-controlx mb-2"> 
                            <label for="priorityName" class="form-label">Priority Name<span class="requiredCls">*</span></label> 
                            <input type="text" class="form-control" name="priorityName" id="priorityName" placeholder="enter priority name"  v-model="v$.form.priorityName.$model"> 
                            <div class="input-errors" v-for="(error, index) of v$.form.priorityName.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>  
                        <div class="formmginput-controlx mb-2"> 
                            <label for="priorityColor" class="form-label">Priority Color<span class="requiredCls">*</span></label> 
                            <input type="color" class="form-control" name="priorityColor" id="priorityColor" placeholder="enter priority name" v-model="v$.form.priorityColor.$model"> 
                        </div>  
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="btn btn-primary" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-on:click="addPriority" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="btn btn-primary" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import $ from "jquery";
import {
    required,
    minLength
} from '@vuelidate/validators'
export default {
    name: 'PriorityAddEditPage',
    data() {
        return {
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            form: {
                priorityName: '',
                priorityColor: '',
            },
        }
    },
    methods: {
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        async modalOpen() {
            this.errorMessage = ''
            this.form.priorityName = '';
            this.form.priorityColor = this.getRandomColor();
            this.isNewImg = 0;
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.v$.$reset()
            document.getElementById('priorityEditId').value = '';
            document.getElementById('addPriorityBtn').click();
            setTimeout(async () =>
                $("#priorityName").focus(),
            500);
        },
        async modalEditOpen() {
            let priority_id = $('.priorityValId').val();
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.priorityName = '';
            this.form.priorityColor = '';
            this.modalType = 'Edit',
            this.buttonName = 'Update',
            document.getElementById('priorityName').value = '';
            document.getElementById('priorityColor').value = '';
            this.v$.$reset()
            this.formLoader = 2
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'priority/get', {
                priority_id: priority_id
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        document.getElementById('priorityName').value = resp.data.data.priority;
                        document.getElementById('priorityColor').value = resp.data.data.color;
                        document.getElementById('priorityEditId').value = resp.data.data._id;
                        this.v$.form.priorityName.$model = resp.data.data.priority;
                        this.v$.form.priorityColor.$model = resp.data.data.color;
                        document.getElementById('addPriorityBtn').click();
                        this.v$.$reset()
                        $('.loadimg').hide();
                        setTimeout(async () =>
                            $("#priorityName").focus(),
                        500);
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    // this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.formLoader = 1
                    // this.errorRes = error.response.data.message;
                })
        },
        async addPriority() {
            this.formLoader = 2
            let _post = {}
            let _URL = ''
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            var priorityName = $('#priorityName').val();
            var priorityColor = $('#priorityColor').val();
            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'priority/update'
                var priority_id = $('#priorityEditId').val();
                _post = {
                    priority_id: priority_id,
                    priority: priorityName,
                    color: priorityColor,
                }
            } else {
                _URL = process.env.VUE_APP_API_URL + 'priority/create'
                _post = {
                    priority: priorityName,
                    color: priorityColor,
                }
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePriorityPop').click();
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                priorityName: {
                    required,
                    min: minLength(2)
                },
                priorityColor: {
                    // required,
                    // min: minLength(2)
                }
            },
        }
    },

}
</script>
