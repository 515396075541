<template>
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#teamProfileModals" id="addTeamProfileBtn"> </button>             
    <div class="modal fade right" id="teamProfileModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">            
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="teamEditId" id="teamEditId">
                    <h5 class="modal-title">{{ modalType }} Team Member</h5> 
                    <button type="button" class="btn-close" id="closeProfilePop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="teamFrm" id="teamFrm">
                    <div class="modal-body setformbackform">
                        <div class="row">
                            <div class="col-sm-12">
                                <span v-if="errorMessage != ''">
                                    <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                                </span>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="profileName" class="form-label">Member Name<span class="requiredCls">*</span></label> 
                                    <input type="text" class="form-control" name="profileName" id="profileName" placeholder="Enter member name"  v-model="v$.form.profileName.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.profileName.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="profileEmail" class="form-label">Member Email<span class="requiredCls">*</span></label> 
                                    <input type="email" class="form-control" name="profileEmail" id="profileEmail" placeholder="Enter member email"  v-model="v$.form.profileEmail.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.profileEmail.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2">
                                    <div class="formmginput-controlx mb-2">
                                        <label for="passwordProfileEdit" class="form-label">Password</label>
                                        <input type="password" class="form-control" name="passwordProfileEdit" placeholder="Enter password" id="passwordProfileEdit" v-model="v$.form.passwordProfileEdit.$model">
                                        <div class="input-errors" v-for="(error, index) of v$.form.passwordProfileEdit.$errors" :key="index">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                               <div class="formmginput-controlx mb-2">
                                    <label for="userProfile_image" class="form-label">User Image</label>
                                    <div class="formmginput-controlx_inner fileintset setfileupload d-flex justify-content-between">
                                        <input type="file" class="form-control" name="userProfile_image" id="userProfile_image" accept="image/png, image/gif, image/jpeg" @change="readURL()">
                                        <div class="setupload">
                                            <img id="blah" src="../../assets/images/No_image_available.svg.png" alt="logo image" class="img-thumbnail" />
                                            <input type="hidden" value="../assets/No_image_available.svg.png" id="imgVal" />
                                        </div>
                                    </div>                                    
                                </div> 
                                <!-- <div class="input-errors">
                                    <div class="error-msg  nonecls" id="imgErrorCls">Please select image</div>
                                </div> -->
                            </div>                            
                        </div>
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-on:click="addTeam" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import $ from "jquery";
import {
    email,
    required,
    // maxLength,
    minLength
} from '@vuelidate/validators'

export default {
    name: 'ProfilePage',
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            logoFile: null,
            form: {
                profileName: '',
                profileEmail: '',
                userProfile_image: '',
                password: '',
                passwordProfileEdit: '',
            },
        }
    },
    methods: {
        async modalEditProfileOpen() {
            // alert('000'+ this.$store.state.user.userInfo._id);
            let team_id = this.$store.state.user.userInfo._id;
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.profileName = '';
            this.form.profileEmail = '';
            this.form.passwordProfileEdit = '';
            // this.form.teamAddress = '';
            // this.form.teamPhoneNumber = '';
            this.modalType = 'Edit',
            this.buttonName = 'Update',
            document.getElementById('profileName').value = '';
            document.getElementById('profileEmail').value = '';
            this.v$.$reset()
            this.formLoader = 2
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'user/get', {
                user_id: team_id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('profileName').value = resp.data.data.name;
                    document.getElementById('profileEmail').value = resp.data.data.email;
                    // document.getElementById('teamAddress').value = resp.data.data.address;
                    // document.getElementById('teamPhoneNumber').value = resp.data.data.phone_number;
                    document.getElementById('teamEditId').value = resp.data.data._id;
                    this.v$.form.profileName.$model = resp.data.data.name;
                    this.v$.form.profileEmail.$model = resp.data.data.email;
                    // this.v$.form.teamAddress.$model = resp.data.data.address;
                    // this.v$.form.teamPhoneNumber.$model = resp.data.data.phone_number;
                    document.getElementById('blah').src = this.img_url + '' + resp.data.data.user_image
                    document.getElementById('addTeamProfileBtn').click();
                    this.v$.$reset()
                    $('.loadimg').hide();
                    setTimeout(async () =>
                        $("#profileName").focus(),
                    500);
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        readURL() {
            this.logoFile = document.getElementById('userProfile_image').files[0]
            var selectedFile = document.getElementById('userProfile_image').files[0];
            var img = document.getElementById('blah')
            var reader = new FileReader();
            reader.onload = function () {
                img.src = this.result
            }
            this.isNewImg = 1;
            // document.getElementById('imgErrorCls').style.display = 'none';

            if(selectedFile){
                reader.readAsDataURL(selectedFile);
            }

        },
        async addTeam() {
            this.formLoader = 2
            let _URL = ''
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            const fd = new FormData;

            var profileName = $('#profileName').val();
            var profileEmail = $('#profileEmail').val();
            // var teamPhoneNumber = $('#teamPhoneNumber').val();
            // var teamAddress = $('#teamAddress').val();

            fd.append('name', profileName)
            fd.append('email', profileEmail)
            // fd.append('phone_number', teamPhoneNumber)
            // fd.append('address', teamAddress)
            fd.append('user_image', this.logoFile)
            _URL = process.env.VUE_APP_API_URL + 'user/update'       
            fd.append('password', this.form.passwordProfileEdit)         
            fd.append('user_id', this.$store.state.user.userInfo._id)         
 
            await axios.post(_URL, fd)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closeProfilePop').click();
                    this.$store.state.user.userInfo = resp.data.data
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
                .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                profileName: {
                    required,
                    min: minLength(2)
                },
                profileEmail: {
                    email,
                    required,
                },
                passwordProfileEdit: {
                    min: minLength(6)
                },
            },
        }
    },
}
</script>
<style scoped>
.img-thumbnail{
    width: 100px;
    height: 100px;
}
</style>