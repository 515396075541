<template>
    <span class="d-none">PriorityData</span>
</template>
<script>
import axios from 'axios'
export default {
    name: 'RolesData',
    data() {
        return {
            roleOptions: [],
            roleFilterOptions: [],
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.roleDataList();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.roleDataList();
            }
            return newValue
        },
    },
    methods: {
        async roleDataList() {
            if (this.$store.state.isActiveToken == '1' && this.$store.state.role.loadRoleData === 1) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                await axios.get(process.env.VUE_APP_API_URL + 'role/active')
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            const roleOpts = resp.data.data.records
                            this.roleOptions = [];
                            this.roleFilterOptions = [];
                            var len = roleOpts.length;
                            for (var i = 0; i < len; i++) {
                                this.roleOptions.push({
                                    id: roleOpts[i]._id,
                                    text: roleOpts[i].role
                                });
                                this.roleFilterOptions.push({
                                    value: roleOpts[i]._id,
                                    label: roleOpts[i].role
                                });
                            }
                            this.$store.state.role.loadRoleData = 2
                            this.$store.state.role.RoleList = this.roleOptions
                            this.$store.state.role.RoleFilterList = this.roleFilterOptions
                        }
                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                    })
            }
        },
    },
}
</script>
