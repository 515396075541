<template>    
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.86046 17.9582C7.86046 17.4574 7.45439 17.0513 6.95349 17.0513H0.906977C0.406072 17.0513 0 17.4574 0 17.9582C0 18.4591 0.406072 18.8652 0.906977 18.8652H6.95349C7.45439 18.8652 7.86046 18.4591 7.86046 17.9582Z" fill="#1D627A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1396 4.65594C18.1396 4.15503 18.5457 3.74896 19.0466 3.74896H25.0931C25.594 3.74896 26.0001 4.15503 26.0001 4.65594C26.0001 5.15684 25.594 5.56292 25.0931 5.56292H19.0466C18.5457 5.56292 18.1396 5.15684 18.1396 4.65594Z" fill="#1D627A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9999 17.9582C25.9999 17.4574 25.5938 17.0513 25.0929 17.0513H14.2092C13.7083 17.0513 13.3022 17.4574 13.3022 17.9582C13.3022 18.4591 13.7083 18.8652 14.2092 18.8652H25.0929C25.5938 18.8652 25.9999 18.4591 25.9999 17.9582Z" fill="#1D627A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.65594C0 4.15503 0.406072 3.74896 0.906977 3.74896H11.7907C12.2916 3.74896 12.6977 4.15503 12.6977 4.65594C12.6977 5.15684 12.2916 5.56292 11.7907 5.56292H0.906977C0.406072 5.56292 0 5.15684 0 4.65594Z" fill="#1D627A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5815 15.1163C12.0842 15.1163 13.3024 16.3345 13.3024 17.8372C13.3024 19.3399 12.0842 20.5582 10.5815 20.5582C9.07879 20.5582 7.86058 19.3399 7.86058 17.8372C7.86058 16.3345 9.07879 15.1163 10.5815 15.1163ZM15.1164 17.8372C15.1164 15.3326 13.0861 13.3023 10.5815 13.3023C8.07696 13.3023 6.04663 15.3326 6.04663 17.8372C6.04663 20.3418 8.07696 22.3721 10.5815 22.3721C13.0861 22.3721 15.1164 20.3418 15.1164 17.8372Z" fill="#80C1D8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4187 1.81395C13.916 1.81395 12.6977 3.03216 12.6977 4.53488C12.6977 6.03761 13.916 7.25581 15.4187 7.25581C16.9214 7.25581 18.1396 6.03761 18.1396 4.53488C18.1396 3.03216 16.9214 1.81395 15.4187 1.81395ZM10.8838 4.53488C10.8838 2.03033 12.9141 0 15.4187 0C17.9233 0 19.9536 2.03033 19.9536 4.53488C19.9536 7.03943 17.9233 9.06977 15.4187 9.06977C12.9141 9.06977 10.8838 7.03943 10.8838 4.53488Z" fill="#80C1D8"/>
    </svg>

</template>
<script>
export default {
    name: 'FilterSvgIconPage',
}
</script>