<template>
    
    <svg width="152" height="88" viewBox="0 0 152 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.0824 43.383C43.6425 41.905 44.5196 38.6315 43.0416 36.0715C41.5635 33.5114 38.2901 32.6343 35.73 34.1123C33.17 35.5904 32.2929 38.8638 33.7709 41.4239C35.249 43.9839 38.5224 44.861 41.0824 43.383Z" fill="#A358DF"/>
        <rect x="36.2544" y="64.1605" width="10.2668" height="26.4004" transform="rotate(-74.3838 36.2544 64.1605)" fill="#FDAB3D"/>
        <rect x="88.6758" y="27.232" width="7.27609" height="18.71" transform="rotate(-111.213 88.6758 27.232)" fill="#DFF0FF"/>
        <rect x="139.472" y="58.0259" width="4.38955" height="11.2874" transform="rotate(-81.5741 139.472 58.0259)" fill="#F9DADE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62725 36.4055C11.4558 36.4055 12.9381 34.9232 12.9381 33.0947C12.9381 31.2662 11.4558 29.7838 9.62725 29.7838C7.79872 29.7838 6.31641 31.2662 6.31641 33.0947C6.31641 34.9232 7.79872 36.4055 9.62725 36.4055Z" fill="#F65F7C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M139.721 35.7944C141.549 35.7944 143.031 34.3121 143.031 32.4835C143.031 30.655 141.549 29.1727 139.721 29.1727C137.892 29.1727 136.41 30.655 136.41 32.4835C136.41 34.3121 137.892 35.7944 139.721 35.7944Z" fill="#E6E9EF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M88.6113 48.1686C89.9651 48.1686 91.0625 47.0712 91.0625 45.7174C91.0625 44.3637 89.9651 43.2662 88.6113 43.2662C87.2576 43.2662 86.1602 44.3637 86.1602 45.7174C86.1602 47.0712 87.2576 48.1686 88.6113 48.1686Z" fill="#FAA1F1"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="46" height="31">
        <rect y="57.3483" width="45.4831" height="30.6517" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask0)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.377 106.494C30.4568 106.494 38.6281 98.3226 38.6281 88.2429C38.6281 78.1631 30.4568 69.9918 20.377 69.9918C10.2973 69.9918 2.12598 78.1631 2.12598 88.2429C2.12598 98.3226 10.2973 106.494 20.377 106.494Z" fill="#FF5AC4"/>
        </g>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="54" height="32">
            <rect x="1.97754" width="52.4045" height="31.6404" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask1)">
            <rect x="38.2065" y="-28.8791" width="18.5091" height="49.3576" transform="rotate(33.9474 38.2065 -28.8791)" fill="#00CC6F"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.6006 18.6335C55.3111 18.6335 56.6977 17.2469 56.6977 15.5364C56.6977 13.8259 55.3111 12.4392 53.6006 12.4392C51.8901 12.4392 50.5034 13.8259 50.5034 15.5364C50.5034 17.2469 51.8901 18.6335 53.6006 18.6335Z" fill="#9CD326"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M99.0615 75.8105C100.772 75.8105 102.159 74.4239 102.159 72.7134C102.159 71.0029 100.772 69.6162 99.0615 69.6162C97.351 69.6162 95.9644 71.0029 95.9644 72.7134C95.9644 74.4239 97.351 75.8105 99.0615 75.8105Z" fill="#66CCFF"/>
    </svg>

</template>
<script>
export default {
    name: 'HeaderBackgroundSvgIconPage',
}
</script>