<template>
    <div class="task-comment-form-container">
        <p class="fw-title mb-2 d-flex justify-content-between align-content-center">
            <i>
                <span> 
                    <ActivityTaskPreSvgIcon />
                </span>
                <b>Activity</b>
            </i>
            <!-- <a href="javascript:;" class="hiddetails hideActivityCls" v-on:click="hideActivity()">Show Details</a>
            <a href="javascript:;" class="hiddetails showActivityCls d-none" v-on:click="showActivity()">Hide Details</a> -->
        </p> 
        <div class="taskActivityMainBlock">
            <!-- {{ notiData }} -->
            <div class="task_activity_main_block shimmer-wrapper" v-if="comLoader == 1">
                <div class="row">
                    <!-- <ComponentLoader /> -->                        
                    <div class="task_activity_main_block" v-for="index in pageLength" :key="index">
                        <div class="task_activity_main_block_row">
                            <div class="task_activity_main_block_header">
                                <div class="task_activity_main_block_header_left align-items-center w-100">
                                    <div class="mg-thumbnail shimmer-line-full shimmer-animate"></div> 
                                    <div class="settask_break_div">  
                                        <b class="shimmer-line shimmer-line-br shimmer-line-w60 shimmer-animate"></b>  
                                        <div class="task_activity_main_block_footer">
                                            <p class="shimmer-line shimmer-line-br shimmer-line-w30x shimmer-animate"></p>
                                        </div> 
                                    </div>                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="task_activity_main_block" v-else v-for="(item) in notiData" :key="item._id">
                <div class="task_activity_main_block_row">
                    <div class="task_activity_main_block_header">
                        <div class="task_activity_main_block_header_left align-items-center">
                            <div class="mg-thumbnail">
                                <img v-bind:src=" item.user_image" v-bind:alt="item.name" v-bind:title="item.name" />
                            </div>   
                            <div class="settask_break_div">  
                                <b v-bind:title="item.name">{{ item.name }} <span>{{ item.message }}</span></b>  
                                <div class="task_activity_main_block_footer">
                                    <!-- <p><i>{{ dateChangeFormat(item.createdAt) }}</i></p> -->
                                    <!-- <p v-bind:title="dateChangeFormat(item.createdAt)"> -->
                                    <p v-tooltip="dateChangeFormat(item.createdAt)">
                                        <i>
                                            <timeago :datetime="item.createdAt"/>
                                        </i>
                                    </p>

                                </div> 
                            </div>              
                        </div>
                    </div>
                    <!-- <div class="task_activity_main_block_body">
                        
                    </div> -->
                    <!-- <div class="task_activity_main_block_footer">
                        <p><i>{{ dateChangeFormat(item.createdAt) }}</i></p>
                    </div> -->
                </div>
            </div>
            <div class="add_actionformat d-flex justify-content-center">
                <a href="javascript:;" class="hiddetails showMoreDetCls" v-if="totalPage > 1" v-on:click="showMoreActivity()">View More</a>
            </div>
        </div>
    </div>
</template>
<script>
// import ComponentLoader from '../../Widgets/Icon/ComponentLoader.vue'
import $ from "jquery";
import axios from 'axios'
import moment from 'moment'
import ActivityTaskPreSvgIcon from '@/components/Widgets/Icon/ActivityTaskPreSvgIcon'
export default {
    name: 'ActivityCommentPage',
    components: {
        // ComponentLoader,
        ActivityTaskPreSvgIcon,
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            notiData: [],
            comLoader: 1,
            actypage: 1,
            totalPage: 1,
            pageLength: 5,
            totalRecord: 0,
            totCat: 0,
            totalLenght: 0    
        }
    },
    async mounted() {
        this.notiData = [];
        this.actypage = 1;
    },
    methods: {
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        async checkActivity() {
            if ($(".hideActivityCls").hasClass("d-none")) {
                this.hideActivity();
            // } else {
                // this.showActivity();
            }
        },
        async hideActivity() {
            $('.taskActivityMainBlock').toggleClass('d-none')
            $('.showActivityCls').toggleClass('d-none')
            $('.hideActivityCls').toggleClass('d-none')
            await this.getTaskActivity(1)
        },
        async showActivity() {
            $('.taskActivityMainBlock').toggleClass('d-none')
            $('.showActivityCls').toggleClass('d-none')
            $('.hideActivityCls').toggleClass('d-none')
        },
        async showMoreActivity() {
            this.actypage = this.actypage + 1;
            this.getTaskActivity(1);
        },
        async getTaskActivity(val) {
            if (val == 2) {
                this.actypage = 1
                this.comLoader = 1
            }
            if (this.actypage == 1) {
                this.notiData = [];
            }
            this.startLoader()
            // this.comLoader = 1
            // this.notiData = [];
            // let task_id = $('.taskValId').val();
            const task_ids = [];
            task_ids.push($('.taskValId').val());
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'dashboard/get-notifications', {
                params: {
                    task_id: task_ids,
                    page: this.actypage,
                    pageLength: this.pageLength
                }
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {

                    // this.notiData = resp.data.data.records

                    const notiOpts = resp.data.data.records
                    var len = notiOpts.length;
                    if (this.actypage == 1) {
                        this.notiData = []
                    }
                    for (var i = 0; i < len; i++) {
                        this.notiData.push({
                            _id: notiOpts[i]._id,
                            is_view: notiOpts[i].is_view,
                            message: notiOpts[i].message,
                            user_image: this.img_url+''+notiOpts[i].user_image,
                            createdAt: notiOpts[i].createdAt,
                            name: notiOpts[i].name,
                            initials: notiOpts[i].initials,
                            project_title: notiOpts[i].project_title,
                            project_id: notiOpts[i].project_id,
                            task_title: notiOpts[i].task_title,
                            task_id: notiOpts[i].task_id,
                            task_no: notiOpts[i].task_no,
                        });
                    }
                    this.totalRecord = resp.data.data.totalRecord
                    this.totalLenght = this.totalRecord / this.pageLength
                    this.totalLenght = Math.ceil(this.totalLenght);
                    this.totalLenght = Math.ceil(this.totalLenght);
                    // const totRec = this.totalRecord / this.totalLenght
                    this.totalPage = 1; 
                    // if (totRec > 1) {
                    //     this.totalPage = 2; 
                    // }
                    this.totCat = this.actypage * this.pageLength
                    if (this.totCat < this.totalRecord) {
                        this.totalPage = 2; 
                        
                    }
                    
                    if (this.notiData.length > 0) {
                        this.comLoader = 3
                    } else {
                        this.comLoader = 2
                    }
                    this.finishLoader()
                }
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.notiFormLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        dateChangeFormat(dateVal) {
            return moment(dateVal).format('DD MMM YYYY')+' at '+ moment(dateVal).format('hh:mm A');
        },
    },

}
</script>