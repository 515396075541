<template>
    <div class="tab-pane fade active show" id="nav-role" role="tabpanel" aria-labelledby="nav-role-tab">
        <div class="card border-0">
            <div class="sectionclsbodymain tsk_body_right_back">
                <div class="serach_controls_main tsk_controls_main_cls">
                    <div class="serach_controls_main_row1">
                        <div class="tsk_top_filter_left">     
                            <div class="serach_controls me-2">
                                <SearchSvgIcon />
                                <input type="search" class="formmginput-control tsk_search" id="searchTxtId" placeholder="Search..." v-on:keyup="reloadSearch()">
                            </div>
                        </div>
                        <div class="tsk_top_filter_right"> 
                            <div class="serach_controlsc w-200 me-2">                    
                                <Select2 v-model="myValue" 
                                v-bind:id="'roleStatusFilterId'"
                                :select=2 
                                :options="$store.state.stausOptions" 
                                :placeholder="'Select Status'"
                                :settings="{ minimumResultsForSearch: -1 }"
                                @change="myChangeEvent($event)" 
                                @select="mySelectEvent($event)"
                                />
                            </div>  
                            <AddEditRole @reload-table-data="reloaRoleData" ref="childComponentRef"></AddEditRole>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody class="tbodyCls" v-if="tableLoader == 1">
                                <tr class="text-center">
                                    <td colspan="7">
                                        <ComponentLoader />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="tbodyCls" v-else-if="tableLoader === 2">
                                <tr class="text-center">
                                    <td colspan="7">
                                        <ComponentNoData />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="tbodyCls" v-else>
                                <tr v-for="(item) in roleList" :key="item._id">
                                    <td>{{ item.role }}</td>
                                    <td v-if="item.status === true">
                                        <div class="form-check form-switch ">
                                            <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" checked="" v-on:click="roleStatusModal(item._id, item.status)">
                                            <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                    </div>
                                    </td>
                                    <td v-else>
                                        <div class="form-check form-switch d-flex align-items-center">
                                            <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" v-on:click="roleStatusModal(item._id, item.status)">
                                            <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                    </div>
                                    </td>
                                    <td>
                                        <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="modalEditOpen(item._id)" title="Edit">
                                            <EditSvgIcon />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex px-3 databottom justify-content-between align-content-center">
                        <p v-if="page * pageLength > totalRecord">Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{
                            formatMoney(totalRecord) }} of {{ formatMoney(totalRecord) }} entries</p>
                        <p v-else>Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{ formatMoney(page * pageLength) }} of {{
                            formatMoney(totalRecord) }} entries</p>
                        <vs-pagination :total="totalLenght" :max="8" v-model="page" @click="reloadPagination(page)"></vs-pagination>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" class="roleValId" id="roleValId"/>
        <input type="hidden" class="roleStatusId" id="roleStatusId"/>
        
        
    </div>
</template>

<script>
import axios from 'axios'
import $ from "jquery";
import Select2 from 'vue3-select2-component';
import AddEditRole from './AddEditRole.vue'
import EditSvgIcon from '../Widgets/Icon/EditSvgIcon.vue'
import SearchSvgIcon from '../Widgets/Icon/SearchSvgIcon.vue'
import ComponentLoader from '../Widgets/Icon/ComponentLoader.vue'
import ComponentNoData from '../Widgets/Icon/ComponentNoData.vue'
import {
    formatMoney
} from '../../helper'
import {
    ref
} from 'vue';
export default {
    name: 'RolesPage',
    components: { 
        Select2,
        EditSvgIcon,
        AddEditRole,
        SearchSvgIcon,
        ComponentNoData,
        ComponentLoader
    },
    data() {
        return {
            activeConfirm:false,
            currentx: 5,
            tableLoader: 1,
            page: 1,
            pageLength: this.$store.state.showEntries,
            searchKey: '',
            totalRecord: 0,
            totalLenght: 0,
            roleList: [],
            myValue: 'true',
        }
    },
    async mounted() {
        this.loadRolesData();
        let temps = this
        $("body").on("click", '#searchTxtId', function () {
            if ($('#searchTxtId').val() != '') {
                $('#searchTxtId').val('');
                this.searchKey = '';
                this.page = 1;
                temps.reloadSearch()
            }
        });
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        formatMoney,
        checkHeightScroll() {
            $('.table').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.table').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom

            cntSubvar = this.windowHeight - cntSubvar - 36

            $('tbody').height(cntSubvar);
            this.checkHeightScroll();
        },
        buttonId(id) {
            return "role" + id;
        },
        myChangeEvent(val) {
            console.log(val);
        },
        reloaRoleData() {
            this.loadRolesData();
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text })
            this.page = 1;
            this.loadRolesData();
        },
        async reloadSearch() {
            const searchCls = document.getElementById('searchTxtId').value;
            this.searchKey = searchCls.trim();
            this.page = 1;
            this.loadRolesData();
        },
        
        async modalEditOpen(role_id) {
            $('.roleValId').val(role_id);
            this.callChildMethod();
        },
        async loadRolesData() {
            this.tableLoader = 1
            let token = localStorage.getItem('mgTasktoken');
            if (!token) {
                this.$store.state.user.accessToken = ''
            }
            let roleStatusFilterId = document.getElementById('roleStatusFilterId').value
            let status = roleStatusFilterId;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'role/list', {
                    params: {
                        page: this.page,
                        pageLength: this.pageLength,
                        searchKey: this.searchKey,
                        status: status,
                    }
                })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.roleList = []
                        this.roleList = resp.data.data.records
                        this.totalRecord = resp.data.data.totalRecord
                        this.totalLenght = this.totalRecord / this.pageLength
                        this.totalLenght = Math.ceil(this.totalLenght);
                        if (this.roleList.length > 0) {
                            this.tableLoader = 3
                        } else {
                            this.tableLoader = 2
                        }
                        setTimeout(async () =>
                            this.onResize(),
                        500);
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
        async reloadPagination(itemObjKey) {
            if (itemObjKey > 0 && itemObjKey !== -1 && itemObjKey <= this.totalLenght) {
                this.page = itemObjKey;
                this.loadRolesData();
            }
        },
        async roleStatusModal(_id, status) {
            document.getElementById('roleStatusId').value = status
            document.getElementById('roleValId').value = _id
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Confirm`,
                text: 'Are you sure you want to update status?',
                accept: this.roleStatusChange,
                cancel: this.roleStatusCloseChange
            })
        },
        async roleStatusCloseChange() {
            const roleStatusId = document.getElementById('roleStatusId').value
            let roleValId = document.getElementById('roleValId').value
            let roleValIdsds = true;
            if (roleValId === 'false') {
                roleValIdsds = false;
            }
            document.getElementById("role" + roleStatusId).checked = roleValIdsds;

            this.loadRolesData();
        },
        async roleStatusChange() {
            this.formLoader = 2
            const roleStatusId = document.getElementById('roleStatusId').value
            let roleValId = document.getElementById('roleValId').value
            let roleValIdsds = false;
            if (roleStatusId === 'false') {
                roleValIdsds = true;
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'role/status-update', {
                status: roleValIdsds,
                role_id: roleValId,
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        this.loadRolesData();
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.data;
                        this.errorRes = resp.data.message;
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                })
        },
    },
    setup() {
        const childComponentRef = ref(null);

        // Call the child component method
        const callChildMethod = () => {
            if (childComponentRef.value) {
                childComponentRef.value.modalEditOpen();
            }
        };

        return {
            childComponentRef,
            callChildMethod
        };
    },
}
</script>