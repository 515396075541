<template>
    <div class="todoMainCls d-flex flex-column">     
        <div class="row">
            <div class="col-sm-12">
                <ComponentLoader v-if="todoLoader == 1"/>
                <div class="d-flex selback_attachment onlyborderspace flex-column w-100" v-bind:class="'todoMainCls-'+itemTodo._id" v-for="(itemTodo) in taskTodoData" :key="itemTodo._id" v-else>
                    <div class="list" style="width: 100%;">
                        <div class="fw-title d-flex justify-content-between align-content-center mb-2">
                            <i class="d-flex align-items-center">
                                <span class="chageiconsvg">                                        
                                    <ChecklistSvgIcon />
                                </span>
                                <b>{{itemTodo.todo_title}}</b>
                            </i>
                            <div class="d-flex">
                                 <span class="w-ds me-2" v-bind:class="itemTodo._id + '-todoItemBtnCls'">
                                    <button type="button" class="mg-btn-gold-light h-30" v-on:click="openTodoCheckBox(itemTodo._id)">New Item</button>
                                </span>
                                <a href="javascript:;" class="mg-btn-delete-light h-30 hideActivityCls" v-on:click="removeTodoCheckBox(itemTodo._id)">Delete</a>                               
                            </div>
                        </div>   
                        <div class="d-flex flex-column">
                            <div class="list-group-item" v-for="(itemList) in itemTodo.todo_subtasks" :key="itemList._id">  
                                <div class="form-check" v-if="itemList.item_complete === 1">
                                    <input type="checkbox" class="form-check-input" value="" v-bind:dataid="itemList._id" v-bind:id="itemList._id" v-bind:class="itemList._id + '-todoCheckCls'" v-on:click="todoCheckedBox(itemList._id, itemTodo._id)" checked>
                                    <label class="form-check-label" v-bind:for="itemList._id">
                                        {{ itemList.subtodo_title }}
                                    </label>
                                </div>
                                <div class="form-check" v-else>
                                    <input type="checkbox" class="form-check-input" value="" v-bind:dataid="itemList._id" v-bind:id="itemList._id" v-bind:class="itemList._id + '-todoCheckCls'" v-on:click="todoCheckedBox(itemList._id, itemTodo._id)" >
                                    <label class="form-check-label" v-bind:for="itemList._id">
                                        {{ itemList.subtodo_title }}
                                    </label>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="inputContainer mt-2 setformbackform" v-bind:class="itemTodo._id + '-todoItemCls'" style="display:none">
                        <input placeholder="New todo" class="input form-control" type="text" value="" v-bind:class="itemTodo._id + '-todoItemTxtCls'" v-on:keyup="checkTodoItemTxt(itemTodo._id)">
                        <div class="setcnansbox d-flex mt-2">
                            <button type="button" class="mg-btn-gold me-2" v-on:click="openTodoCheckAddBox(itemTodo._id)" v-bind:class="itemTodo._id + '-todoItemAddCls'" disabled><span>Add</span></button>
                            <div class="cancel mg-btn-gold mg-btn-cancel" v-on:click="openTodoCheckCancelBox(itemTodo._id)">Cancel</div>
                        </div>
                    </div>
                    
                </div>  
            </div>                              
        </div>                                
    </div>
</template>
<script>
import $ from "jquery";
import axios from 'axios'
import ComponentLoader from '../../Widgets/Icon/ComponentLoader.vue'
import ChecklistSvgIcon from "@/components/Widgets/Icon/ChecklistSvgIcon.vue";
export default {
    name: 'TodoListTaskPage',
    emits: ["reload-activity-data"],
    components: {
        ComponentLoader,
        ChecklistSvgIcon
    },
    data() {
        return {
            taskTodoData: [],
            todoLoader: 1,
        }
    },
    methods: {
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        async todoCheckedBox(todoCheckId, todoId) {
            var todoListVal = 0
            if($("."+todoCheckId+"-todoCheckCls").prop('checked') == true){
                todoListVal = 1
            }
            this.startLoader()
                let _URL = ''
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
                _URL = process.env.VUE_APP_API_URL + 'task/todo-list-update'
                _post = {
                    task_id: $('.taskValId').val(),
                    todoListId: todoCheckId,
                    todoListVal: todoListVal,
                    todo_id: todoId,
                }
                await axios.post(_URL, _post)
                    .then((resp) => {
                        this.finishLoader()
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        // this.getTaskTodo();
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                })
        },
        async removeTodoCheckBox(todo_id) {
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task/remove-todo-list', {
                to_do_id: todo_id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    $('.todoMainCls-' + todo_id).remove();
                    this.$emit('reload-activity-data');
                    this.$toast.success(resp.data.message);
                }
                this.finishLoader()
                
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                // this.errorRes = error.response.data.message;
            })
        },
        async openTodoCheckBox(todo_id) {
            $('.'+todo_id+'-todoItemTxtCls').val('');
            $('.'+todo_id+'-todoItemCls').toggle();
            $('.'+todo_id+'-todoItemAddCls').prop('disabled', true);
            $('.'+todo_id+'-todoItemBtnCls').toggle();
        },
        async openTodoCheckCancelBox(todo_id) {
            $('.'+todo_id+'-todoItemTxtCls').val('');
            $('.'+todo_id+'-todoItemCls').toggle();
            $('.'+todo_id+'-todoItemAddCls').prop('disabled', true);
            $('.'+todo_id+'-todoItemBtnCls').toggle();
        },
        async checkTodoItemTxt(todo_id) {
            let todoItemTitle = $('.'+todo_id+'-todoItemTxtCls').val();
            todoItemTitle = todoItemTitle.trim();
            $('.'+todo_id+'-todoItemAddCls').prop('disabled', true);
            if(todoItemTitle.length > 0){
                $('.'+todo_id+'-todoItemAddCls').prop('disabled', false);
            }

        },
        async openTodoCheckAddBox(todo_id) {
            let todoItemTitle = $('.'+todo_id+'-todoItemTxtCls').val();
            todoItemTitle = todoItemTitle.trim();
            if(todoItemTitle.length > 0){
                this.todoItemTitle = todoItemTitle.trim();
                console.log("todoItemTitle ->",todoItemTitle);
                if(this.todoItemTitle.length > 0){
                    let _URL = ''
                    this.startLoader()
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                    let _post = {}
                    _URL = process.env.VUE_APP_API_URL + 'task/add-new-item'
                    _post = {
                        item: todoItemTitle,
                        todo_id: todo_id,
                        task_id: $('.taskValId').val(),
                    }
                    await axios.post(_URL, _post)
                        .then((resp) => {
                            if (resp.status == 200 && resp.data.status == 'success') {
                                this.$toast.success(resp.data.message);
                                $('.'+todo_id+'-todoItemTxtCls').val('')
                                this.openTodoCheckCancelBox(todo_id);
                                this.getTaskTodo();
                                this.$emit('reload-activity-data');
                                this.finishLoader()
                        } else if (resp.status == 200 && resp.data.status == 'failure') {
                            this.errorMessage = resp.data.message;
                        }
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                        this.errorRes = error.response.data.message;
                        this.formLoader = 1
                    })    
                } 
            }
        },
        async getTaskTodo() {
            let task_id = $('.taskValId').val();
            this.startLoader()
            this.todoLoader = 1
            this.taskTodoData = []
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'task/todo-list', {
                params: {
                    task_id: task_id
                }
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.taskTodoData = resp.data.data.records
                    this.finishLoader()
                    this.todoLoader = 2
                    $('.loadimg').hide();
                }
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
    },
}
</script>