<template>
  <main  v-if="$store.state.isLogin === 1">
    <Admin></Admin>
  </main>
  <main  v-else-if="$store.state.isLogin === 2">
    <Auth></Auth>
  </main>
  <main class="vh-100" v-else>
    <Auth></Auth>
  </main>
  <vue-progress-bar></vue-progress-bar>
</template>
<script>
import Auth from './pages/layout/Auth.vue'
import Admin from './pages/layout/Admin.vue'
import $ from "jquery";
export default {
  name: 'App',
  components: {
    Auth,
    Admin
  },
  data() {
    return {
      isAdmin: 1
    }
  },
  mounted() {
      if(this.$store.state.isLogin === 1){
        $('body').removeClass('loginbody');
      }
    },
  methods:{
    start () {
        this.$Progress.start()
    },
  },
}
</script>

