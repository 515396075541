<template>
  <nav class="navbar clsnavbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow headNavCls">
    <!-- Sidebar Toggle (Topbar) -->
    <!-- <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
    </button> -->
    <h2 class="pageTitleCls">
      <ul>
        <li>
          <router-link to="/">Dashboard</router-link>
        </li>
        <li class="projectBethCls" style="display:none">
          <router-link to="/projects">Projects</router-link>
        </li>
        <li>
          <span>{{ $store.state.pageTitle }}</span>
        </li>
        <!-- <li>
          <span class="mr-2 d-lg-inline text-gray-600 small">{{ $store.state.user.userInfo.email }}</span>
        </li> -->
      </ul>  
    </h2>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto align-items-center d-none">
      <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle notilinkCls" href="#" role="button" id="dropdownMenuLinkForNotification" data-bs-toggle="dropdown" aria-expanded="false" disabled v-on:click="openNotificationBtn">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#6546d2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell icon"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                  <!-- Counter - Alerts -->
                  <!-- <span class="badge badge-danger badge-counter">{{ notiCnt }}</span> -->
                  <span class="bg_tsk_ani dots"></span>
            </a>
        
            <!-- <ul class="dropdown-menu notilinkCls" id="dropdownMenuForNotification" aria-labelledby="dropdownMenuLinkForNotification">
              <spam v-if="notiFormLoader === 1">
                <li class="dropdown-header" v-if="notiCntData > 0">
                  <a href="javascript:;" v-on:click="markAsReadBtn">
                    <span class="badge rounded-pill bg-primary p-2 ms-2">Mark all as read</span>
                  </a>
                </li>
                <li class="notification-item" v-for="(item) in notiData" :key="item._id">
                  <div class="mg-thumbnail" v-if="item.initials == null">
                    <img v-bind:src="img_url + '' + item.user_image" v-bind:alt="item.name" v-bind:title="item.name"/>
                  </div>
                  <div class="mg-thumbnail" v-else>
                    <span v-bind:title="item.name">{{ item.initials }}</span>
                  </div>
                  <div class="notiTitle" >
                    {{ item.name }}
                  </div>
                  <div class="notiDateTime" >
                    {{ dateChangeFormat(item.createdAt) }}
                    {{ notificationIds(item._id) }}
                  </div>
                  <div class="notiMsg" >
                      {{ item.message }}
                  </div>
                  <div class="notiTaskTitle" v-if="item.task_title != ''">
                    <span>Task: </span>{{ item.task_title }}
                  </div>
                  <div class="notiProTitle" >
                      <span>Project: </span>{{ item.project_title }}
                  </div>
                </li>
                <li class="dropdown-footer">
                  <router-link to="notifications" >Show all notifications</router-link>
                </li>
              </spam>
              <spam v-else>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </spam>
            </ul> -->
          </li>
      <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle" href="javascript:;" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ $store.state.user.userInfo.name }}</span>
            <img v-bind:src="img_url+''+$store.state.user.userInfo.user_image" alt="Profile" class="img-profile rounded-circle">
          </a>
            <ul class="dropdown-menu dropdown-menu-left shadow animated--grow-in" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="javascript:;">Profile</a></li>
              <li><a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#logoutModals">Sign Out</a></li>
            </ul>
        </li>
    </ul>
</nav>

<div class="modal fade confirmationset" id="logoutModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h5 class="modal-title">Confirm Change?</h5> 
      </div>
      <div class="modal-body"> 
        <div class="col-12 text-center"> 
          <label for="bankName" class="form-label mb-0">Are you sure you want to logout?</label> 
        </div>
      </div>
      <div class="modal-footer justify-content-center d-flex">
          <button variant="success"  class="mg-btn-gold h-30 me-2" v-on:click="logout()">Yes</Button> 
          <button variant="outline-primary" class="mg-btn-delete-lightx h-30 md" id="closeBtn" data-bs-dismiss="modal" >No</Button>         
      </div>
    </div>
  </div>
</div>

</template>
<script>
import axios from 'axios'
import $ from "jquery";
import moment from 'moment'
export default {
    name: 'HeaderPage',
    data() {
      return {
        img_url: process.env.VUE_APP_IMG_URL,
        notiCnt: 0,
        notiOpenCnt: 0,
        notiCntData: 0,
        notiFormLoader: 1,
        notiData: [],
        notiIdData: [],
      }
    },
    mounted() { 
      $("body").on("click", "#sidebarToggle, #sidebarToggleTop", function () {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        /* if ($(".sidebar").hasClass("toggled")) {
          $('.sidebar .collapse').collapse('hide');
        } */
      });
    },
    computed: {
        // notoken() {
        //     return this.$store.state.NotifiCheckVal
        // },
    },
    watch: {
      // async notoken(newValue) {
      //   await this.getCountNotifications()
      //   return newValue
      // },
    },
    methods: {
      // async getCountNotifications() {
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
      //   await axios.get(process.env.VUE_APP_API_URL + 'dashboard/count-notifications')
      //   .then((resp) => {
      //       if (resp.status == 200 && resp.data.status == 'success') {
      //           this.notiCnt = resp.data.data;
      //       }
      //       this.formLoader = 1
      //   })
      //   .catch(error => {
      //       // this.errorMessage = error.message;
      //       console.error("There was an error!", error);
      //       console.log("response", error.response.status);
      //       console.log("message", error.response.data.message);
      //       this.formLoader = 1
      //       // this.errorRes = error.response.data.message;
      //   })
      // },
      async getNotifications() {
        this.notiFormLoader = 2 
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
        await axios.get(process.env.VUE_APP_API_URL + 'dashboard/get-notifications')
        .then((resp) => {
            if (resp.status == 200 && resp.data.status == 'success') {
                
                this.notiData = resp.data.data
                this.notiCntData = resp.data.data.length
            }
            this.notiFormLoader = 1
        })
        .catch(error => {
            // this.errorMessage = error.message;
            console.error("There was an error!", error);
            console.log("response", error.response.status);
            console.log("message", error.response.data.message);
            this.notiFormLoader = 1
            // this.errorRes = error.response.data.message;
        })
      },
      async openNotificationBtn() {
        if(this.notiOpenCnt == 1 && $('.notilinkCls').hasClass('show')){
          this.notiOpenCnt = 0
          $('.notilinkCls').addClass('hide');
          $('.notilinkCls').removeClass('show');
        }else{
          this.notiOpenCnt = 1
          $('.notilinkCls').addClass('show');
          $('.notilinkCls').removeClass('hide');
          this.getNotifications();
        }
      },
      async markAsReadBtn() {
        let _URL = ''
                
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
        let _post = {}

        _URL = process.env.VUE_APP_API_URL + 'dashboard/mark-as-read'   
        _post = {
          notification_id: this.notiIdData
        }

        await axios.post(_URL, _post)
        .then((resp) => {
          if (resp.status == 200 && resp.data.status == 'success') {
              this.$toast.success(resp.data.message);
              this.getNotifications();
          } else if (resp.status == 200 && resp.data.status == 'failure') {
              this.errorMessage = resp.data.message;
          }
              this.formLoader = 1
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          console.log("response", error.response.status);
          console.log("message", error.response.data.message);
          this.errorRes = error.response.data.message;
          this.formLoader = 1
      }) 
      },
      notificationIds(id) {
          return this.notiIdData.push(id);
      },
      dateChangeFormat(dateVal) {
          return moment(dateVal).format('DD-MM-YYYY hh:mm A');
      },
      async logout() {
          document.getElementById('closeBtn').click();
          this.$store.state.user.accessToken = ''
          this.$store.state.isActiveToken = ''
          this.$store.state.user.userInfo = ''
          localStorage.removeItem("userInfo");
          localStorage.removeItem("mgTasktoken");
          this.$router.push({
              name: 'Login'
            })
        }
    },
}
</script>
<style >
a.nav-link{
    color:white !important;
}
.navbar-brand{
    color:white
}
</style>