<template>
    <RolesData></RolesData>   
    <TeamMember></TeamMember>   
</template>
<script>
import TeamMember from '../components/TeamMember/index.vue'
import RolesData from '../components/Roles/RolesData.vue'
import $ from "jquery";
export default {
    name: 'TeamMemberPage',
    components: {
        RolesData,
        TeamMember,
    },
    async mounted() { 
        const bHeight = $('body').height();
        $('#content').addClass('firstHeightCls')
        $('.firstHeightCls').height(bHeight-23) 
        this.$store.state.isLogin = 1    
        this.$store.state.NotifiCheckVal = Math.ceil(Math.random()*1000000)   
    },

}
</script>