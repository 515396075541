<template>
    <span class="d-none">BoardData</span>
</template>
<script>
import axios from 'axios'
export default {
    name: 'BoardData',
    data() {
        return {
            boardOptions: [],
            boardFilterOptions: [],
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.boardDataList();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.boardDataList();
            }
            return newValue
        },
    },
    methods: {
        async boardDataList() {
            if (this.$store.state.isActiveToken == '1' && this.$store.state.board.loadBoardData === 1) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                await axios.get(process.env.VUE_APP_API_URL + 'boardstatus/active')
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            const boardOpts = resp.data.data.records
                            this.boardOptions = [];
                            this.boardFilterOptions = [];
                            var len = boardOpts.length;
                            for (var i = 0; i < len; i++) {
                                this.boardOptions.push({
                                    id: boardOpts[i]._id,
                                    text: boardOpts[i].board_status
                                });
                                this.boardFilterOptions.push({
                                    value: boardOpts[i]._id,
                                    label: boardOpts[i].board_status
                                });
                            }
                            this.$store.state.board.loadBoardData = 2
                            this.$store.state.board.BoardList = this.boardOptions
                            this.$store.state.board.BoardFilterList = this.boardFilterOptions
                        }
                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                    })
            }
        },
    },
}
</script>