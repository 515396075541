<template>
    <div class="dld">
        <img src="../assets/images/imggirl.png">
    </div>
    <div class="justify-content-center flex-column h-100 newbodycardmainpagecls">
         <div class="w-100 text-center mb-5">
            <img src="../assets/images/logo.svg" alt="">
        </div>
        <div class="card newbodycard o-hidden border-0 shadow-lg">
            <div class="card-body p-0">                
                <div class="py-3 px-5">
                  
                    <div class="text-left mb-4">
                        <h1 class="m-0">Welcome</h1>
                        <h2 class="m-0">Sign in</h2>
                    </div>
                    <form @submit.prevent="onSubmit" id="myForm" class="user">
                        <div class="form-group">
                            <label for="yourEmail" class="floating_label">Username</label>
                            <input type="email" name="username" class="form-control form-control-user" id="yourEmail" placeholder="Enter email" v-model="v$.form.email.$model" autofocus>                           
                            <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="yourPassword" class="floating_label">Password</label>
                            <input type="password" name="password" class="form-control form-control-user" id="yourPassword" v-model="v$.form.password.$model" placeholder="Enter password">                            
                            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                                <div class="error-msg ">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox small">
                                <input class="custom-control-input" type="checkbox" name="remember" value="true" id="rememberMe" checked>
                                <label class="custom-control-label" for="rememberMe">Keep me signed in</label>
                            </div>
                        </div>
                        <div class="floating_label_input mt-3">
                            <button class="btn btn-primary btn-main btn-user btn-block" v-on:click="signIn" :disabled="v$.form.$invalid" v-if="loginLoader === 1">Login</button>
                            <button class="btn btn-primary btn-user btn-block" :disabled="v$.form.$invalid" v-else><span class="spinner-border mr-2 spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import {
    required,
    email,
    helpers,
    minLength
} from '@vuelidate/validators'
import $ from "jquery";
export function validName(name) {
    let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
    if (validNamePattern.test(name)) {
        return true;
    }
    return false;
}
export default {
    name: 'LoginPage',
    mounted() { 
        this.$store.state.isLogin = 0
        $('body').addClass('bg-gradient-primary');
        $('body').addClass('loginbody');
    },
    beforeRouteLeave(to, from, next) {
        console.log(to);
        console.log(from);
        console.log(next);
        $('body').removeClass('bg-gradient-primary');
        $('body').removeClass('loginbody');
        next()
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            loginLoader: 1,
            errorMessage: '',
            errorRes: '',
            form: {
                email: '',
                password: '',
            }
        }
    },
    methods: {
        startLoader () {
            this.$Progress.start()
        },
        finishLoader () {
            this.$Progress.finish()
        },
        async signIn() {
            this.startLoader()
            this.loginLoader = 2;
            // this.$router.push({ name: 'Home' });
            await axios.post(process.env.VUE_APP_API_URL+'login', {
                email: this.form.email,
                password: this.form.password,
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        localStorage.setItem("mgTasktoken", resp.data.data.token);
                        this.$store.state.user.accessToken = resp.data.data.token
                        this.$store.state.user.userInfo = resp.data.data.user
                        this.$store.state.user.roleId = resp.data.data.user.role_id
                        this.$store.state.isActiveToken = 1
                        this.loginLoader = 1;
                        this.$store.state.attemptLogin = 2
                        this.finishLoader()
                        this.$router.push({ name: 'Home' })
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.loginLoader = 1;
                        this.$toast.error(resp.data.message);
                    }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
            })
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required: helpers.withMessage('Please enter email id', required),
                    // min: minLength(6)
                    email
                },
                password: {
                    required: helpers.withMessage('Please enter password', required),
                    min: minLength(6)
                }
            },
        }
    },
}
</script>