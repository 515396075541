<template>
    <ProjectData />
    <BoardData />
    <PriorityData />
    <TeamMemberData />
    <LabelData />
    <div class="card overflow-hidden bordfrtset">
		<nav class="tsk_tabs">
			<div class="nav nav-tabs m-0" id="nav-tab" role="tablist">
                <router-link to="/tasks" class=" nav-link  " id="nav-list-tab" >
                    <span class="tabLinkCls">List</span>
                </router-link>
                <button class="nav-link active tabLinkCls" id="nav-karban-tab" data-bs-toggle="tab" data-bs-target="#nav-karban" type="button" role="tab" aria-controls="nav-karban" aria-selected="false">Kanban</button>
                <router-link to="/tasks-archive" class="nav-link" id="nav-archive-tab" >
                    <span class="tabLinkCls">Archive</span>
                </router-link>
			</div>
            <div class="mg-right_divselt">
                <vs-button class="mg-btn-gold me-3" v-on:click="manageLabelsBtn"  color="primary" text-color="whiteprimary" v-if="$store.state.user.userInfo.role_name === 'Admin'"><LabelsSvgIcon />Manage labels</vs-button>
                <vs-button class="mg-btn-gold"  v-on:click="addTaskBtn" color="primary" text-color="whiteprimary"><AddSvgIcon />Add Task</vs-button>  
            </div>
		</nav>
		<div class="tab-content p-0" id="nav-tabContent">
            <div class="tab-pane fade active show" id="nav-karban" role="tabpanel" aria-labelledby="nav-karban-tab">
                <div class="card border-0">
                    <div class="sectionclsbodymain tsk_body_right_back">
                        <Karban /> 
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>
<script>
import Karban from '../components/Tasks/karban.vue'
import ProjectData from '../components/Projects/ProjectData.vue'
import PriorityData from '../components/Priority/PriorityData.vue'
import BoardData from '../components/Board/BoardData.vue'
import TeamMemberData from '../components/TeamMember/TeamMemberData.vue'
import LabelData from '../components/Label/LabelData.vue'
import AddSvgIcon from '../components/Widgets/Icon/AddSvgIcon.vue'
import LabelsSvgIcon from '../components/Widgets/Icon/LabelsSvgIcon.vue'
import $ from "jquery";
export default {
    name: 'TasksPage',
    components: {
        ProjectData,
        PriorityData,
        BoardData,
        TeamMemberData,
        LabelData,
        Karban,
        AddSvgIcon,
        LabelsSvgIcon,
    },
    async mounted() {
        const bHeight = $('body').height();
        $('#content').addClass('firstHeightCls')
        $('.firstHeightCls').height(bHeight-23)
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        }
        this.$store.state.projectIdSelected = ''
        $('.taskMenuCls').addClass('router-link-active')
    },
    beforeRouteLeave(to, from, next) {
        console.log(to);
        console.log(from);
        console.log(next);
        console.log('next');
        $('.taskMenuCls').removeClass('router-link-active')
        next()
    },
    methods: {
        async addTaskBtn() {
            document.getElementById('addTaskMainBtn').click();
        },
        async manageLabelsBtn() {
            document.getElementById('addManageLabelsBtn').click();
        }
    },
}
</script>
