<template>  
    <div class="card overflow-hidden">
		<nav class="tsk_tabs">
			<div class="nav nav-tabs m-0" id="nav-tab" role="tablist">
				<button class="nav-link active tabLinkCls" id="nav-role-tab" data-bs-toggle="tab" data-bs-target="#nav-role" type="button" role="tab" aria-controls="nav-role" aria-selected="true">Roles</button>
				<button class="nav-link tabLinkCls" id="nav-priority-tab" data-bs-toggle="tab" data-bs-target="#nav-priority" type="button" role="tab" aria-controls="nav-priority" aria-selected="false">Priority</button>
			</div>
		</nav>
		<div class="tab-content p-0" id="nav-tabContent">
			<Roles></Roles>
			<Priority></Priority>
		</div>
	</div>
    
</template>
<script>
import Roles from '../components/Roles/Role.vue'
import Priority from '../components/Priority/Priority.vue'
export default {
    name: 'SettingPage',
    components: {
        Roles,
        Priority
    },
    async mounted() { 
        this.$store.state.pageTitle = 'Settings'
        this.$store.state.isLogin = 1    
        this.$store.state.NotifiCheckVal = Math.ceil(Math.random()*1000000)   
    },

}
</script>