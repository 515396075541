import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.css'
import './assets/css/custom.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import { createApp } from 'vue'
import App from './App.vue'
import store from './vuex/store'
import Toaster from '@meforma/vue-toaster';
import BootstrapVueNext from 'bootstrap-vue-next'
import router from './router/router'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import tooltip from "../src/assets/css/tooltip.js";
import "../src/assets/css/tooltip.css";
import timeago from 'vue-timeago3'


const options = {
    color: "#c0b7fb",
    failedColor: "#000",
    thickness: "5px",
    transition: {
      speed: "0.2s",
      opacity: "0.6s",
      termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
  };
const app = createApp(App)
app.use(BootstrapVueNext)
app.use(router)
app.use(Vuesax)
app.use(store)
app.use(Toaster)
app.use(timeago)
app.use(VueProgressBar, options)
app.directive("tooltip", tooltip);
app.mount('#app')
import 'bootstrap/dist/js/bootstrap.min.js'